<template>
  <v-card
      :loading="loading"
      class="my-12 mx-3 cursor-pointer"
      max-width="140"
      @click="$router.push({name:'BookDetails',params:{bookId:book.id}})"
  >
    <v-img
        max-width="140"
        :src="book.photo"
    ></v-img>
    <v-card-text class="pa-2 black--text">
      <div class="font-weight-bold">{{ book.title }}</div>
      <div v-if="!book.discount">TK {{ book.price }}</div>
      <div v-else>
        TK {{ book.price - book.discount }}
        <del>{{ book.price }}</del>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['book'],
  data: () => ({
    loading: false,
    selection: 1,
  }),

  methods: {},
}
</script>