<template>
  <v-container class="d-flex flex-wrap">
    <book v-for="book in books" :book="book" :key="book.id"></book>
  </v-container>
</template>
<script>
import Book from "./Book";
import axios from "axios";

export default {
  components: {Book},
  data() {
    return {
      books: [],
    }
  },
  methods: {
    initialize() {
      const url = 'books'
      axios.get(url).then((response) => {
        this.books = response.data
      })
    }
  },
  created() {
    this.initialize()
  }
}
</script>